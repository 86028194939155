<template>
  <div :class="$style.container">
    <span>404</span>
    <br />
    <span :class="$style.subtitle">资源不存在</span>
  </div>
</template>

<script>
export default {
  props: {
    
  }
}
</script>

<style lang="less" module>
.container {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 26px;
  font-weight: bold;
  flex-direction: column;
  .subtitle {
    color: #cccccc;
    font-size: 16px;
    font-weight: normal;
  }
}
</style>